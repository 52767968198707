<template>
  <div class="funding-wallet">
    <fundind-wallet @sucsessFunding="sucsessFunding" @closeFunding="closeFunding"/>
  </div>
</template>

<script>
import FundindWallet from '../../components/helper/rema-components/fundind-wallet'
export default {
  name: 'IndexVue',
  components: { FundindWallet },
  methods: {
    sucsessFunding () {
      this.$router.replace('histories')
    },
    closeFunding () {
      this.$router.replace('histories')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/sass/variables";
</style>
