export const firebaseRef = {
  users: 'users/',
  wallets: 'wallets/',
  walletsWaiting: 'waiting/',
  walletCode: 'walletCodes',
  mainsAdsFeeds: 'adsFeeds/mainFeeds',
  draftAds: 'adsFeeds/draftFeeds',
  invalidatedAds: 'adsFeeds/invalidated',
  viewsCount: 'adsFeeds-meta/viewCount/',
  viewerTab: 'adsFeeds-meta/viewers/',
  clickCount: 'adsFeeds-meta/clickCount/',
  clickerTab: 'adsFeeds-meta/clickers/',
  conversationRef: 'userChatsKeys/',
  chatRef: 'messages/',
  mainsSMSAdsFeeds: 'adsFeeds/smsAdsFeeds',
  draftSMSFeeds: 'adsFeeds/draftSmsFeed',
  invalidedSMS: 'adsFeeds/invalidedSms',
  recoveryFeed: 'recoveryFeedRef',
  pubAudience: 'pubAudience'
}
export const storageRef = {
  userInfosRef: 'userInfos/',
  adsImagesStorages: 'adsImagesStorage',
  adsDraftImagesStorage: 'adsDraftImagesStorage',
  adsFilesStorage: 'adsFilesStorage',
  chatFileRef: 'chatFile'
}
