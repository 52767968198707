export const remaDefaultData = {
  basePubPrice: 0.0035,
  baseEuro: 650,
  baseUrl: 'https://ads.remaapp.com/confirm/',
  emailValidationUrl: 'https://us-central1-rema-da2f4.cloudfunctions.net/sendMail',
  cguLink: 'https://remaapp.com/TermsOfService',
  pdcLink: 'https://remaapp.com/privacyPolice',
  baseUrlPaydunya: 'https://ads.remaapp.com/success-card-funding/',
  baseSMSPrice: 1.3,
  convertAPIKey: 'StMqsbsvL02c8rMB',
  convertCloudFunctions: 'https://us-central1-rema-da2f4.cloudfunctions.net/pptxToImage'
}
